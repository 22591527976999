import React, { useState, useEffect } from "react";
import paintings from "./PaintingList";

import useKeyPress from "./UseKeyPressHook";

import styled from "styled-components";
import Icon from "antd/es/icon";
import "antd/es/icon/style/css";
import Spin from "antd/es/spin";
import "antd/es/spin/style/css";

import { Link, useParams, useHistory } from "react-router-dom";

const PaintingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: auto;
`;

const Thumbnail = styled(Link)`
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  cursor: pointer;
  width: 250px;
  height: 300px;
  margin: 18px;
  transition: 0.3s all;

  :hover {
    margin-top: 12px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PaintingViewer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 80vh;
  max-width: 100%;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const Painting = styled.img`
  max-height: 65vh;
  max-width: 100%;
`;

const Details = styled.div`
  padding: 20px 30px 10px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
`;

const Year = styled.span`
  font-weight: normal;
  color: #aaaaaa;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
`;

const ButtonDivider = styled.div`
  padding: 0px 15px 3px;
  color: #eeeeee;
  font-size: 18px;
`;

const ActionButton = styled(Icon)`
  cursor: pointer;
  padding: 10px;
`;

const paintingKeys = Object.keys(paintings);

export default () => {
  const [selectedPainting, setSelectedPainting] = useState(null);
  const [loading, setLoading] = useState(true);
  const escPress = useKeyPress("Escape");
  const arrowRightPress = useKeyPress("ArrowRight");
  const arrowLeftPress = useKeyPress("ArrowLeft");

  const { paintingId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    if (paintingId !== undefined) {
      const idIndex = paintingKeys.indexOf(paintingId);
      if (idIndex >= 0) setSelectedPainting(idIndex);
    } else {
      setSelectedPainting(null);
    }
  }, [paintingId]);

  const closePaintingViewer = () => {
    history.push(`/`);
    console.log("wow closing");
  };

  const goBack = () => {
    let newIndex = selectedPainting - 1;
    if (newIndex < 0) newIndex = paintingKeys.length - 1;

    history.push(`/paintings/${paintingKeys[newIndex]}`);
  };

  const goForward = () => {
    let newIndex = selectedPainting + 1;
    if (newIndex >= paintingKeys.length) newIndex = 0;

    history.push(`/paintings/${paintingKeys[newIndex]}`);
  };

  useEffect(() => {
    if (escPress) closePaintingViewer();
    if (arrowLeftPress) goBack();
    if (arrowRightPress) goForward();
  }, [escPress, arrowLeftPress, arrowRightPress]);

  if (selectedPainting !== null) {
    const selectedPaintingKey = paintingKeys[selectedPainting];
    const { file, title, year } = paintings[selectedPaintingKey];
    return (
      <PaintingViewer>
        <Spin spinning={loading} wrapperClassName="painting-loading-wrapper">
          <Painting src={file} onLoad={() => setLoading(false)} />
        </Spin>

        <Details>
          {title} <Year>{year}</Year>
        </Details>

        <ButtonRow>
          <ActionButton type="close" onClick={closePaintingViewer} />
          <ButtonDivider>|</ButtonDivider>
          <ActionButton type="arrow-left" onClick={() => goBack()} />
          <ButtonDivider>/</ButtonDivider>
          <ActionButton type="arrow-right" onClick={() => goForward()} />
        </ButtonRow>
      </PaintingViewer>
    );
  }

  return (
    <PaintingsWrapper>
      {paintingKeys.map((key, index) => (
        <Thumbnail
          to={`/paintings/${key}`}
          image={paintings[key].thumbnail}
        />
      ))}
    </PaintingsWrapper>
  );
};
