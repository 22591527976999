import React from "react";
import styled from "styled-components";

const AboutWrapper = styled.div`
  max-width: 50vw;
  font-size: 21px;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;

  @media (max-width: 768px) {
    max-width: 95vw;
    margin-top: 30px;
    font-size: 18px;
  }
`;

export default () => {
  return (
    <AboutWrapper>
      <p>Somalee is an artist and a doctor.</p>
      <p>
        Somalee's work centers around allegories of the intersectionality of
        craft, medicine and the post colonial south asian attachment to
        embellishment. Her recent work draws on her work as an internal medicine
        doctor with acrylic paintings of organs referencing anatomy create
        altars to health and disease with embellishments that echo the popular
        aesthetic of jewelry and clothing in South Asia. She has a Bachelor of
        Fine Arts in Painting from the Sam Fox School of Art at Washington
        University (2008) and a subsequent MD from Washington University. She
        currently has a studio practice in Oakland, California while practicing
        medicine.
      </p>
    </AboutWrapper>
  );
};
