import Allie2008 from "../images/paintings/Allie2008.jpg";
import emilysTeaParty2007 from "../images/paintings/emilysTeaParty2007.jpg";
import FamilyConnections2007 from "../images/paintings/FamilyConnections2007.jpg";
import findingAHome2007 from "../images/paintings/findingAHome2007.jpg";
import Fireflies2008 from "../images/paintings/Fireflies2008.jpg";
import heart2019 from "../images/paintings/heart2019.jpg";
import Ignacio2019 from "../images/paintings/Ignacio2019.jpg";
import immigration2007 from "../images/paintings/immigration2007.jpg";
import leon2019 from "../images/paintings/leon2019.jpg";
import pancreas2019 from "../images/paintings/pancreas2019.jpg";
import responsibleDaughter2007 from "../images/paintings/responsibleDaughter2007.jpg";
import sailingDownTheSeine2008 from "../images/paintings/sailingDownTheSeine2008.jpg";
import SareeCloset2016 from "../images/paintings/SareeCloset2016.jpg";
import starrySky2010 from "../images/paintings/starrySky2010.jpg";
import tooMuchHair2009 from "../images/paintings/tooMuchHair2009.jpg";
import TVHangout2008 from "../images/paintings/TVHangout2008.jpg";
import Weddings2008 from "../images/paintings/Weddings2008.jpg";

import Allie2008_thumb from "../images/thumbnails/Allie2008.jpg";
import emilysTeaParty2007_thumb from "../images/thumbnails/emilysTeaParty2007.jpg";
import FamilyConnections2007_thumb from "../images/thumbnails/FamilyConnections2007.jpg";
import findingAHome2007_thumb from "../images/thumbnails/findingAHome2007.jpg";
import Fireflies2008_thumb from "../images/thumbnails/Fireflies2008.jpg";
import heart2019_thumb from "../images/thumbnails/heart2019.jpg";
import Ignacio2019_thumb from "../images/thumbnails/Ignacio2019.jpg";
import immigration2007_thumb from "../images/thumbnails/immigration2007.jpg";
import leon2019_thumb from "../images/thumbnails/leon2019.jpg";
import pancreas2019_thumb from "../images/thumbnails/pancreas2019.jpg";
import responsibleDaughter2007_thumb from "../images/thumbnails/responsibleDaughter2007.jpg";
import sailingDownTheSeine2008_thumb from "../images/thumbnails/sailingDownTheSeine2008.jpg";
import SareeCloset2016_thumb from "../images/thumbnails/SareeCloset2016.jpg";
import starrySky2010_thumb from "../images/thumbnails/starrySky2010.jpg";
import tooMuchHair2009_thumb from "../images/thumbnails/tooMuchHair2009.jpg";
import TVHangout2008_thumb from "../images/thumbnails/TVHangout2008.jpg";
import Weddings2008_thumb from "../images/thumbnails/Weddings2008.jpg";

export default {
  heart2019: {
    file: heart2019,
    thumbnail: heart2019_thumb,
    title: "Heart",
    year: "2019"
  },
  Ignacio2019: {
    file: Ignacio2019,
    thumbnail: Ignacio2019_thumb,
    title: "Ignacio",
    year: "2019"
  },
  FamilyConnections2007: {
    file: FamilyConnections2007,
    thumbnail: FamilyConnections2007_thumb,
    title: "Family Connections",
    year: "2007"
  },
  emilysTeaParty2007: {
    file: emilysTeaParty2007,
    thumbnail: emilysTeaParty2007_thumb,
    title: "Emily's Tea Party",
    year: "2007"
  },
  findingAHome2007: {
    file: findingAHome2007,
    thumbnail: findingAHome2007_thumb,
    title: "Finding A Home",
    year: "2007"
  },
  Fireflies2008: {
    file: Fireflies2008,
    thumbnail: Fireflies2008_thumb,
    title: "Fireflies",
    year: "2008"
  },
  pancreas2019: {
    file: pancreas2019,
    thumbnail: pancreas2019_thumb,
    title: "Pancreas",
    year: "2019"
  },
  immigration2007: {
    file: immigration2007,
    thumbnail: immigration2007_thumb,
    title: "Immigration",
    year: "2007"
  },
  leon2019: {
    file: leon2019,
    thumbnail: leon2019_thumb,
    title: "Leon",
    year: "2019"
  },
  responsibleDaughter2007: {
    file: responsibleDaughter2007,
    thumbnail: responsibleDaughter2007_thumb,
    title: "Responsible Daughter",
    year: "2007"
  },
  sailingDownTheSeine2008: {
    file: sailingDownTheSeine2008,
    thumbnail: sailingDownTheSeine2008_thumb,
    title: "Sailing Down The Seine",
    year: "2008"
  },
  Weddings2008: {
    file: Weddings2008,
    thumbnail: Weddings2008_thumb,
    title: "Weddings",
    year: "2008"
  },
  starrySky2010: {
    file: starrySky2010,
    thumbnail: starrySky2010_thumb,
    title: "Starry Sky",
    year: "2010"
  },
  Allie2008: {
    file: Allie2008,
    thumbnail: Allie2008_thumb,
    title: "Allie",
    year: "2008"
  },
  tooMuchHair2009: {
    file: tooMuchHair2009,
    thumbnail: tooMuchHair2009_thumb,
    title: "Too Much Hair",
    year: "2009"
  },
  TVHangout2008: {
    file: TVHangout2008,
    thumbnail: TVHangout2008_thumb,
    title: "TV Hangout",
    year: "2008"
  },
  SareeCloset2016: {
    file: SareeCloset2016,
    thumbnail: SareeCloset2016_thumb,
    title: "Saree Closet",
    year: "2016"
  },
};