import React from "react";
import Icon from "antd/es/icon";
import "antd/es/icon/style/css";
import styled from "styled-components";

const ContactWrapper = styled.div`
  max-width: 50vw;
  font-size: 21px;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;

  @media (max-width: 768px) {
    max-width: 95vw;
    margin-top: 30px;
    font-size: 18px;
  }
`;

const ContactIcon = styled(Icon)`
  font-size: 21px;
  margin-right: 10px;
`

export const instagramLink = "https://www.instagram.com/somalee/";

export default () => {
  return <ContactWrapper>
    <p>For any commission enquiries please contact Somalee directly at</p>
     <p><ContactIcon type="mail" /><a href="mailto:somalee.art@gmail.com">somalee.art@gmail.com</a></p>
    <p><ContactIcon type="instagram" /><a href={instagramLink} target="_blank" rel="noopener noreferrer">@somalee</a></p> 
  </ContactWrapper>;
};
