import React from "react";
import "./App.css";
import styled from "styled-components";
import Paintings from "./components/Paintings";
import About from "./components/About";
import Contact, { instagramLink } from "./components/Contact";
import logo from "./images/logo.svg";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";

const AppContainer = styled.div`
  max-width: 80vw;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    max-width: 85vw;
  }
`;

const AppHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 18vh;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
  }
`;

const Logo = styled.img``;

const Menu = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    margin-top: 20px;
    max-width: 280px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const MenuLink = styled(NavLink)`
  cursor: pointer;
  margin: 0px 10px;
  font-size: 18px;
  font-family: "Nunito", sans-serif;
  font-weight: 400;

  :hover {
    color: #000;
  }
`;

const Content = styled.div`
  flex-basis: 80vh;
`;

const Footer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: #aaaaaa;
`

const PAGE_NAMES = {
  Paintings: "Paintings",
  About: "About",
  Contact: "Contact"
  // Instagram: <InstagramIcon type="instagram" />
};

const PATHS = {
  Paintings: "/",
  About: "/about",
  Contact: "/contact"
  // Instagram: <InstagramIcon type="instagram" />
};

function App() {

  const navigateTo = page => {
    if (page === "Instagram") window.open(instagramLink, "_blank");
  };

  return (
    <Router>
      <AppContainer className="App">
        <AppHeader>
          <NavLink to="/"><Logo src={logo} alt="Somalee Banerjee" /></NavLink>
          <Menu>
            {Object.keys(PAGE_NAMES).map(page => (
              <MenuLink
                onClick={() => navigateTo(page)}
                to={PATHS[page]}
                exact
                style={{ color: "#9c9c9c" }}
                activeStyle={{
                  color: "#000"
                }}
              >
                {PAGE_NAMES[page]}
              </MenuLink>
            ))}
          </Menu>
        </AppHeader>
        <Content>
          <Switch>
            <Route exact path={["/", "/paintings/:paintingId"]}>
              <Paintings />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
          </Switch>
        </Content>
        <Footer>© Somalee Banerjee 2020</Footer>
      </AppContainer>
    </Router>
  );
}

export default App;
